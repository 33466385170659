import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { InlineWidget } from "react-calendly"
import { GatsbyImage } from "gatsby-plugin-image"

const PageContainer = styled.div`
  text-align: center;
  width: 100%;
  padding: 0px 1.25em;
  margin-bottom: 100px;
  @media (min-width: ${breakpoints.md}) {
    padding-top: 2em;
  }
  @media (min-width: ${breakpoints.lgw}) {
    padding-left: 5em;
    padding-right: 5em;
  }
`

const ContentContainer = styled.div`
  max-width: 1320px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${breakpoints.md}) {
    gap: 80px;
  }
`

const PageTitle = styled.h1`
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 20px;
  @media (min-width: ${breakpoints.lgw}) {
    font-size: 34px;
  }
`

const Content = styled.div`
  text-align: left;

  p {
    margin-bottom: 12px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  hr {
    display: block;
    opacity: 0;
    margin: 1rem;
    height: 0.14em;
  }

  i {
    font-style: italic;
  }

  a {
    text-underline-position: under;

    :hover {
      text-decoration: underline;
    }
  }

  h2 {
   font-weight: 600; 
   margin-bottom: 20px;
  }

`

const Grid = styled.div`
  display: grid;
  gap: 40px;
  text-align: left;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    align-items: flex-start;
  }
  @media (min-width: ${breakpoints.lgw}) {
    gap: 80px;
  }
`

const CalendlyWidgetWrapper = styled.div`
  max-width: 678px;
  .calendly-inline-widget {
    height: 420px !important;
  }
  @media (min-width: 690px) {
    .calendly-inline-widget {
      height: 600px !important;
    }
  }
`

const TextCol = styled.div`
  max-width: 500px;
`

const ContactPage = ({ data: { content }, location }) => {
  const path = location.pathname

  const pageTitle = "Contact"

  const parsedRawDescription = JSON.parse(content.description.raw)
  let metaDescription = parsedRawDescription.content[0]?.content[0]?.value

  if (!metaDescription) {
    metaDescription =
      pageTitle +
      " - Greg White photographer. Represented by Picture Club. Award winning for his commercial and personal projects, specialising in landscape, technology and automotive photography."
  } else if (metaDescription.length > 255) {
    metaDescription = metaDescription.slice(0, 253) + "..."
  }

  const options = {
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return (
    <Layout>
      <Seo
        fullTitle={false}
        path={path}
        title={pageTitle}
        description={metaDescription}
      />
      <PageContainer>
        <ContentContainer>
          <Grid>
            <div>
              <GatsbyImage image={content.image.gatsbyImageData} />
            </div>
            <TextCol>
              <PageTitle>{content.contentTitle}</PageTitle>
              <Content>{renderRichText(content.content, options)}</Content>
            </TextCol>
          </Grid>
          <Grid>
            <div>
              <CalendlyWidgetWrapper>
                <InlineWidget url="https://calendly.com/gregwhitephotography" />
              </CalendlyWidgetWrapper>
            </div>
            {content.contactInformation && (
              <TextCol>
                <Content>
                  {renderRichText(content.contactInformation, options)}
                </Content>
              </TextCol>
            )}
          </Grid>
        </ContentContainer>
      </PageContainer>
    </Layout>
  )
}

export default ContactPage

export const contactPageQuery = graphql`
  query {
    content: contentfulTextPage(pageTitle: { eq: "Contact" }) {
      contentTitle
      content {
        raw
      }
      description {
        raw
      }
      contactInformation {
        raw
      }
      image {
        gatsbyImageData
      }
    }
  }
`
